import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import './Test.css';
import { useEffect, useState } from 'react';
import WaitingPanel from './WaitingPanel';

export default function Exams(){    
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const navigate = useNavigate();
    const login = () => {
        navigate('/login');
    }
    const [isLoading, setLoading] = useState(false);
    const query = useQuery();
    const categoryName = atob(query.get('param1'));
    const [exams, setExams] = useState([]);
    const [examStatuses, setExamStatuses] = useState({}); // Store the statuses of exams

    useEffect(() => {
        const fetchAllExams = async () => {
            setLoading(true);
            let username=localStorage.getItem('student')
            const res1 = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM students WHERE EmailAddress='${username}'`)
            const data1 = await res1.json()
            console.log(data1)
            if(data1.status){
                let colgName = data1.message[0].CollegeName
                let batchName = data1.message[0].BatchName
                const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/
                    SELECT * FROM exam_assign WHERE CategoryName='${categoryName}' AND CollegeName='${colgName}' AND BatchName='${batchName}' AND isAccess=true`);
                const data = await res.json();
                console.log(data)
                let examArray=[]
                if (data.status) {
                    let array = data.message
                    for(let ele of array){
                        let temp_ExamId=ele.ExamId
                        const res2 = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM exams WHERE SrNo=${temp_ExamId}`)
                        const data2 = await res2.json()
                        if(data2.status){
                            examArray=[...examArray,data2.message[0]]
                        }
                    }
                    console.log(examArray)
                    setExams(examArray)
                }
                setLoading(false);
            }
            
        }
        fetchAllExams();
    }, [categoryName]); // Run only when categoryName changes

    useEffect(() => {
        const fetchExamStatuses = async () => {
            const updatedStatuses = {};
            for (let exam of exams) {
                const status = await getExamActualStatus(exam.SrNo);
                updatedStatuses[exam.SrNo] = status;
            }
            setExamStatuses(updatedStatuses);
        };
        if (exams.length > 0) {
            fetchExamStatuses();
        }
    }, [exams]); // Run when exams are loaded

    const getExamActualStatus = async (examId) => {
        let emailAddress = localStorage.getItem('student');
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT Status FROM test_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`);
        const data = await res.json();
        if (data.status && data.message.length > 0) {
            return data.message[0].Status === 'COMPLETED';
        }
        return false;
    }

    const getExamStatus = (examEndDate) => {
        const givenDate = new Date(examEndDate);
        const currentDate = new Date();
        return givenDate >= currentDate;
    }

    const navigateToTest = () => {
        navigate('/test');
    }

    const openExam = (examId, examCategory) => {
        if (examStatuses[examId]) {
            const newTabUrl = `${window.location.origin}/test/solution?examId=${btoa(examId)}`;
            window.open(newTabUrl, '_blank');
        } else {
            const encodedParameter1 = btoa(examId.toString());
            const encodedParameter2 = btoa(examCategory.toString());
            navigate(`/exam?examId=${encodedParameter1}&examCategory=${encodedParameter2}`);
        }
    }

    const getDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, 
            timeZone: 'UTC' 
        };
        const localDate = date.toLocaleString('en-US', options).replace(',', '');
        return localDate.slice(0, 10) + ' ' + localDate.slice(11);
    }

    return (
        <>
            <Header/>
            {isLoading && <WaitingPanel/>}
            {
                localStorage.getItem('student') === null ? (
                    <div className='login-information'>
                        <h2>Please Login first !!</h2>
                        <button onClick={login}>Click here for login</button>
                    </div>
                ) : (
                    <>
                        <div className='exam-category'>
                            <div className='title'>
                                <h2>Your Exams</h2>
                            </div>
                            <div className="back">
                                <i className="fa-solid fa-chevron-left"></i>
                                <p onClick={navigateToTest}>Back to dashboard</p>
                            </div>
                            <div className='category'>
                                {
                                    exams.length > 0 ? exams.map((item, index) => (
                                        <div className='card' key={index}>
                                            <div className='card-title'>                                                
                                                <h2>{item.ExamName}</h2>
                                                <p>LeadSoft</p>
                                            </div>
                                            <div className="card-content">
                                                <p className={getExamStatus(item.ExamTo) ? (examStatuses[item.SrNo] ? 'active1':'expired') : 'expired'}>
                                                    {getExamStatus(item.ExamTo) ? (examStatuses[item.SrNo] ? 'Attempted' : 'Active') : 'Expired'}
                                                </p>
                                                <div className="box">
                                                    <i className="fa-regular fa-clock"></i>
                                                    <strong>{getDate(item.ExamFrom)}</strong> to <strong>{getDate(item.ExamTo)}</strong>
                                                </div>
                                                <div className="box">
                                                    <i className="fa-solid fa-bookmark"></i>
                                                    <strong>{item.TotalMarks} Marks</strong>
                                                </div>
                                                <div className="box">
                                                    <i className="fa-solid fa-hourglass-end"></i>
                                                    <strong>{item.TotalDuration} mins</strong>
                                                </div>
                                                <button onClick={() => openExam(item.SrNo, item.ExamCategory)}
                                                    disabled={!getExamStatus(item.ExamTo)}>
                                                    {getExamStatus(item.ExamTo) ? (examStatuses[item.SrNo] ? 'View Solution' : 'Open Exam') : 'Expired'}
                                                </button>
                                            </div>
                                        </div>
                                    )) : (
                                        <p>No Exams Found !!</p>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )
            }
            <Footer/>
        </>
    )
}
