import { useLocation, useNavigate } from 'react-router-dom';
import './TestQuestions.css';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import WaitingPanel from './WaitingPanel';

export default function TestQuestions(){
    //for fullscreen
    const hasRun = useRef(false)
    const elementRef=useRef(null);   
    
    //Cookie operations
    const setObjectInCookie = (name, object) => {
        Cookies.set(name, JSON.stringify(object), { expires: 1 }); // Set for 1 day
    };
    const getObjectFromCookie = (name) => {
        const cookieValue = Cookies.get(name);
        return cookieValue ? JSON.parse(cookieValue) : null;
    };
    const removeCookie = (name) => {
        Cookies.remove(name);
    }; 
    const requestFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { 
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { 
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { 
            element.msRequestFullscreen();
        }
    };
    const handleFullscreen = () => {       
        if (elementRef.current) {
            requestFullscreen(elementRef.current);
        }
    };
    const handleFullscreenChange = () => {
        setIsFullscreen(!!document.fullscreenElement);        
    };
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    //getting examId and examCategory from URL
    const query=useQuery();
    const examId=atob(query.get('examId'))   
    const examCategory=atob(query.get('examCategory'))         

    //Loading variables
    const [isLoading,setLoading]=useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [fullName,setFullName]=useState('')
    const [examName,setExamName]=useState('')
    const [examSections,setExamSections]=useState([])
    const navigate=useNavigate() 
    const [sectionIndex,setSectionIndex]=useState(-1)    
    const [questionIndex,setQuestionIndex]=useState(-1)
    const [questionObject,setQuestionObject]=useState(null)
    const [candidateAnswer,setCandidateAnswer]=useState('')
    const [attempted,setAttempted]=useState(0)
    const [skipped,setSkipped]=useState(0)

    const handleAnswer=(e)=>{
        const value=e.target.value
        setCandidateAnswer(value)
    }

    //display remaining time
    const [initialTimeInMinutes,setInitialTimeInMinutes] = useState(0);
    const [initialTimeInSeconds,setInitialTimeInSeconds] = useState(0);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const [timeRemaining, setTimeRemaining] = useState(initialTimeInSeconds); // Remaining time in seconds
    const [displayTime, setDisplayTime] = useState(formatTime(timeRemaining));

    

    useEffect(() => {
        if (timeRemaining > 0) {
            const timerId = setInterval(() => {
                setTimeRemaining(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timerId); // Cleanup interval on component unmount
        }
    }, [timeRemaining]);

    useEffect(() => {
        setDisplayTime(formatTime(timeRemaining));
    }, [timeRemaining]);

    

    //getting student fullname and exam name
    const getname=async()=>{
        setLoading(true)
        let emailAddress = localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT FullName FROM students WHERE EmailAddress='${emailAddress}'`)
        const data = await res.json()
        setLoading(false)
        if(data.status){
            const fetchedFullName = data.message[0].FullName;
            setFullName(fetchedFullName)            
        }
    }  
    const getExamName=async()=>{
        setLoading(true)
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT ExamName FROM exams WHERE SrNo=${examId}`)
        const data = await res.json()
        setLoading(false)
        if(data.status){
            setExamName(data.message[0].ExamName)                
        }
    }  
    const getExamSections=async()=>{
        if(examSections.length!==0) return
        setLoading(true)
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM exam_sections WHERE ExamId=${examId}`)
        const data = await res.json()
        setLoading(false)
        if(data.status){            
            setExamSections(data.message)     
            setSectionIndex(0)       
            setQuestionIndex(0)     
        }
    }  
    const getQuestion=async()=>{
        if(questionIndex===-1) return
        setLoading(true)
        
        setTimeout(() => {
            const getQuestionItem=JSON.parse(localStorage.getItem('sectionDetails'))
            if(getQuestionItem){
                setQuestionObject(getQuestionItem[questionIndex])
            }
        }, 500);

        setLoading(false)
    }
    const timeToSeconds = (time) => {
        const [minutes, seconds] = time.split(':').map(Number);
        return minutes * 60 + seconds;
    };
    
    const timeDifferenceInSeconds = (time1, time2) => {
        const time1Seconds = timeToSeconds(time1);
        const time2Seconds = timeToSeconds(time2);
        return Math.abs(time2Seconds - time1Seconds);
    };
    
    const secondsToTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    const saveAndNext=()=>{        
        const updatedQuestionObject = {
            ...questionObject,
            candidateAnswer: candidateAnswer,
            markStatus: candidateAnswer === questionObject.answer ? true : false,
            status: 'Attempted'
        };
        let getQuestionItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
        getQuestionItem[questionIndex] = updatedQuestionObject;
        localStorage.setItem('sectionDetails', JSON.stringify(getQuestionItem));
        setQuestionObject(null);
        setCandidateAnswer('');
        if(questionIndex+1 === examSections[sectionIndex].NoOfQuestion){
            setTimeout(async() => {
                const sectionElement = examSections[sectionIndex]
                let username = localStorage.getItem('student')
                let sectionName = sectionElement.SectionName
                let duration=sectionElement.Duration
                let noOfQuestion = sectionElement.NoOfQuestion
                let sectionMarks = sectionElement.SectionMarks
                let totalTime=sectionElement.Duration+":00"
                let tt=displayTime
                const differenceInSeconds = timeDifferenceInSeconds(totalTime, tt);
                const differenceInTime = secondsToTime(differenceInSeconds);
                let timeTaken = differenceInTime
                let questionType = sectionElement.QuestionType
                let positive=0,negative=0
                let updatedItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
                updatedItem.forEach((ele)=>{
                    if(ele.markStatus){
                        positive+=1
                    } else if(!ele.markStatus && ele.status!=='Skipped') {
                        negative+=1
                    }
                })
                const sectionObjForSubmit ={
                    emailAddress:username,examId:examId,sectionName:sectionName,
                    duration:duration,noOfQuestions:noOfQuestion,sectionMarks:sectionMarks,
                    timeTaken:timeTaken,questionType:questionType,
                    positive:positive,negative:negative,obtainedMark:positive,skipped:skipped
                }                
                const res = await fetch(`https://api.leadsoftbackend.com/api/student/submitSection`,{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(sectionObjForSubmit)
                })
                const data = await res.json()
                if(data.status){
                    updatedItem.forEach(async(ele)=>{
                        const questionObjForSubmit={
                            emailAddress:username,examId:examId,sectionName:sectionName,
                            questionContent:ele.questionContent,option1:ele.option1,option2:ele.option2,
                            option3:ele.option3,option4:ele.option4,answer:ele.answer,
                            candidateAnswer:ele.candidateAnswer,status:ele.markStatus ? 'Correct' : 'Wrong'
                        }
                        const res1 = await fetch(`https://api.leadsoftbackend.com/api/student/submitSectionQuestions`,{
                            method:'POST',
                            headers:{
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(questionObjForSubmit)
                        })
                        const data1 = await res1.json()
                        if(!data1.status){
                            console.error('Error while storing questions !!')
                        }
                    })
                    localStorage.removeItem('sectionDetails')
                    if(sectionIndex===examSections.length-1) {
                        //submitting the exam
                        navigate(`/test-result?examId=${btoa(examId)}`)
                    } else {
                        setSectionIndex(prev=>prev+1)    
                    }
                } else {
                    console.error('Error while storing the sections !!')
                }
               
            }, 3000);
        } else {
            setTimeout(() => {
                setQuestionIndex(prevIndex => prevIndex + 1);
            }, 300);
        }
        
    }
    const skipAndNext=()=>{        
        const updatedQuestionObject = {
            ...questionObject,
            candidateAnswer:'Not Attempted',
            markStatus: candidateAnswer === questionObject.answer ? true : false,
            status:'Skipped'
        };
        let getQuestionItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
        getQuestionItem[questionIndex] = updatedQuestionObject;
        localStorage.setItem('sectionDetails', JSON.stringify(getQuestionItem));
        setQuestionObject(null);
        setCandidateAnswer('');
        if(questionIndex+1 === examSections[sectionIndex].NoOfQuestion){
            setTimeout(async() => {
                const sectionElement = examSections[sectionIndex]
                let username = localStorage.getItem('student')
                let sectionName = sectionElement.SectionName
                let duration=sectionElement.Duration
                let noOfQuestion = sectionElement.NoOfQuestion
                let sectionMarks = sectionElement.SectionMarks
                let totalTime=sectionElement.Duration+":00"
                let tt=displayTime
                const differenceInSeconds = timeDifferenceInSeconds(totalTime, tt);
                const differenceInTime = secondsToTime(differenceInSeconds);
                let timeTaken = differenceInTime
                let questionType = sectionElement.QuestionType
                let positive=0,negative=0
                let updatedItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
                updatedItem.forEach((ele)=>{
                    console.log(ele)
                    if(ele.markStatus){
                        positive+=1
                    } else if(!ele.markStatus && ele.status!=='Skipped') {
                        negative+=1
                    }
                })
                const sectionObjForSubmit ={
                    emailAddress:username,examId:examId,sectionName:sectionName,
                    duration:duration,noOfQuestions:noOfQuestion,sectionMarks:sectionMarks,
                    timeTaken:timeTaken,questionType:questionType,
                    positive:positive,negative:negative,obtainedMark:positive,skipped:skipped
                }
                const res = await fetch(`https://api.leadsoftbackend.com/api/student/submitSection`,{
                    method:'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body:JSON.stringify(sectionObjForSubmit)
                })
                const data = await res.json()
                if(data.status){
                    updatedItem.forEach(async(ele)=>{
                        const questionObjForSubmit={
                            emailAddress:username,examId:examId,sectionName:sectionName,
                            questionContent:ele.questionContent,option1:ele.option1,option2:ele.option2,
                            option3:ele.option3,option4:ele.option4,answer:ele.answer,
                            candidateAnswer:ele.candidateAnswer,status:ele.markStatus ? 'Correct' : 'Wrong'
                        }
                        const res1 = await fetch(`https://api.leadsoftbackend.com/api/student/submitSectionQuestions`,{
                            method:'POST',
                            headers:{
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(questionObjForSubmit)
                        })
                        const data1 = await res1.json()
                        if(!data1.status){
                            console.error('Error while storing questions !!')
                        }
                    })
                    localStorage.removeItem('sectionDetails')
                    if(sectionIndex===examSections.length-1) {
                        //submitting the exam
                        navigate(`/test-result?examId=${btoa(examId)}`)
                    } else {
                        setSectionIndex(prev=>prev+1)
                        setQuestionIndex(0)
                    }
                    
                } else {
                    console.error('Error while storing the sections !!')
                }
                
            }, 3000);
        } else {
            setTimeout(() => {
                setQuestionIndex(prevIndex => prevIndex + 1);
            }, 300);
        }
    }
    const nextQuestion=()=>{
        saveAndNext()
    }
    const addQuestion = async () => {
        setLoading(true);
        let username = localStorage.getItem('student');

        try {
            const item = examSections[sectionIndex];
            
            if (!item) {
                console.warn("No section found.");
                return;
            }
            const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM questions WHERE SubjectName='${item.SubjectName}' AND ChapterName='${item.ChapterName}' ORDER BY RAND() LIMIT ${item.NoOfQuestion}`);
            const data = await res.json();

            if (data.status) {
                const result = data.message;
                let sectionObj = [];
                let i = 1;

                result.forEach((element) => {
                    let questionObject = {
                        examId: examId,
                        username: username,
                        questionNo: i,
                        questionType: element.QuestionType,
                        difficulty: element.Difficulty,
                        companyTags: element.CompanyTags,
                        questionContent: element.QuestionContent,
                        option1: element.Option1,
                        option2: element.Option2,
                        option3: element.Option3,
                        option4: element.Option4,
                        answer: element.Answer,
                        sectionName: item.SectionName,
                        status: '',
                        candidiateAnswer: '',
                        markStatus: false
                    };
                    sectionObj = [...sectionObj, questionObject];
                    i += 1;
                });
                if (sectionObj.length > 0) {
                    localStorage.setItem('sectionDetails', JSON.stringify(sectionObj));
                } else {
                    console.warn("No questions to add. Local storage not updated.");
                }
            } else {
                console.warn("Failed to fetch questions.");
            }
        } catch (error) {
            console.error("Error adding questions:", error);
        } finally {
            setLoading(false);
        }
    };      
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; 
        };
        // Add the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(()=>{
        if(hasRun.current) return
        getname()
        getExamName()      
        getExamSections()  
        
        hasRun.current=true
    },[])
    
    useEffect(() => {
        const fetchData = async () => {
            if (examSections.length > 0) {
                await addQuestion(); // Ensure addQuestion completes
                setQuestionIndex(0)
                const getQuestionItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
                let aCount = 0, sCount = 0;
    
                // Count the number of attempted and skipped questions
                getQuestionItem.forEach((element) => {
                    if (element.status === 'Skipped') {
                        sCount += 1;
                    } else if (element.status === 'Attempted') {
                        aCount += 1;
                    }
                });
                setQuestionObject(getQuestionItem[questionIndex])
                // Update state with counts
                setAttempted(aCount);
                setSkipped(sCount);
    
                // Calculate and set time
                const durationInMinutes = examSections[sectionIndex].Duration;
                const durationInSeconds = durationInMinutes * 60;
                setInitialTimeInMinutes(durationInMinutes);
                setInitialTimeInSeconds(durationInSeconds);
                setTimeRemaining(durationInSeconds);
            }
        };
    
        fetchData();
    }, [examSections, sectionIndex]);
    useEffect(() => {
        const fetchQuestion = async () => {
            await getQuestion(); // Ensure getQuestion completes            
            const getQuestionItem = JSON.parse(localStorage.getItem('sectionDetails')) || [];
            let aCount = 0, sCount = 0;
    
            // Count the number of attempted and skipped questions
            getQuestionItem.forEach((element) => {
                if (element.status === 'Skipped') {
                    sCount += 1;
                } else if (element.status === 'Attempted') {
                    aCount += 1;
                }
            });
            setQuestionObject(getQuestionItem[questionIndex])
            // Update state with counts
            setAttempted(aCount);
            setSkipped(sCount);
        };
    
        fetchQuestion();
    }, [questionIndex]);
        
    useEffect(()=>{
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    },[])

    return(
        <>
            <div className="test-container" ref={elementRef}>
                {
                    !isFullscreen ? (<>
                        <div className='card'>
                            <h2>Allow fullscreen to proceed with the test?</h2>
                            <button onClick={handleFullscreen}>Allow Fullscreen</button>
                        </div>
                    </>) :(
                    <>
                        {isLoading && <WaitingPanel/>}
                        <div className="test-header">
                            <p className={timeRemaining <=120 && timeRemaining>0 ?'time-red':'time'}>{displayTime}</p>
                        </div>
                        <div className="section-details">
                            <h2>{examSections.length > 0 ? examSections[sectionIndex].SectionName :'Loading...'}</h2>
                            <h2>{examSections.length > 0 ? examSections[sectionIndex].NoOfQuestion +' Q' :'Loading...'}</h2>
                        </div>  
                        <div className="question-details">
                            <div className="question">
                                <div className="sequence">
                                    <div className="data">
                                        <p>Question No :</p>
                                        <p>{questionIndex+1} of {examSections.length > 0 ? examSections[sectionIndex].NoOfQuestion :'Loading...'}</p>
                                    </div>
                                    <p className='difficulty'>
                                        {questionObject ? questionObject.difficulty:'Loading'}
                                    </p>
                                </div>
                                <div className="actual-question">
                                    <p>Question</p>
                                    <p dangerouslySetInnerHTML={{__html:(questionObject?questionObject.questionContent:"<p style={'color':red,'font-weight':bold}>Loading<p>")}}></p>
                                </div>
                                <div className="acknowledge">
                                    <div className="question-action">
                                        <div className="item">
                                            <h3>Skipped :</h3>
                                            <p>{examSections.length > 0 ? (skipped) :'0'}</p>
                                        </div>
                                        <div className="item">
                                            <h3>Attempted :</h3>
                                            <p>{examSections.length > 0 ? (attempted) :'0'}</p>
                                        </div>
                                    </div>
                                    <div className="company-tags">
                                        {
                                            questionObject ? (questionObject.companyTags.split(',').map((item,index)=>{
                                                return(
                                                    <>
                                                        <p key={index}>{item}</p>
                                                    </>
                                                )
                                            })) : (
                                                <p>Loading</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="option">
                                <p className='title'>Options</p>
                                <form>
                                    <div className="item">
                                        <label>
                                            <input name='answer' checked={candidateAnswer==='Option 1'} onChange={handleAnswer} value='Option 1' type='radio'></input>
                                            <p dangerouslySetInnerHTML={{__html:(questionObject?questionObject.option1:"<p style='font-size':'0.91em'>Loading...</p>")}}></p>
                                        </label>                                        
                                    </div>
                                    <div className="item">
                                        <label>
                                            <input name='answer' checked={candidateAnswer==='Option 2'} onChange={handleAnswer} value='Option 2'  type='radio'></input>
                                            <p dangerouslySetInnerHTML={{__html:(questionObject?questionObject.option2:"<p style='font-size':'0.91em'>Loading...</p>")}}></p>
                                        </label>
                                    </div>
                                    <div className="item">
                                        <label>
                                            <input name='answer' checked={candidateAnswer==='Option 3'} onChange={handleAnswer} value='Option 3' type='radio'></input>
                                            <p dangerouslySetInnerHTML={{__html:(questionObject?questionObject.option3:"<p style='font-size':'0.91em'>Loading...</p>")}}></p>
                                        </label>
                                    </div>
                                    <div className="item">
                                        <label>
                                            <input name='answer' checked={candidateAnswer==='Option 4'} onChange={handleAnswer} value='Option 4' type='radio'></input>
                                            <p dangerouslySetInnerHTML={{__html:(questionObject?questionObject.option4:"<p style='font-size':'0.91em'>Loading...</p>")}}></p>
                                        </label>
                                    </div>
                                </form>
                                <div className="action-buttons">
                                    <button onClick={skipAndNext}>Skip Question</button>
                                    <button onClick={nextQuestion} disabled={candidateAnswer===''?true:false}>
                                        {examSections.length > 0 ? (questionIndex+1===examSections[sectionIndex].NoOfQuestion ? 'Submit Section' : 'Save & Next') : 'Loading' }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="question-footer">
                            <p>{fullName}</p>
                            <p>{examCategory}</p>
                            <p>{examName}</p>
                            <p>&copy; LeadSoft Placement Academy</p>
                        </div> 
                    </>
                    )
                }
            </div>
            
        </>
    )
}