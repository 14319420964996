import { useNavigate } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import './style.css'
import './Test.css'
import { useEffect, useState } from 'react'
import WaitingPanel from './WaitingPanel'


export default function Test(){
    const navigate=useNavigate()
    const login=()=>{
        navigate('/login')
    }
    const [college,setCollege]=useState({})
    const [collegeName,setCollegeName]=useState('')
    const [batchName,setBatchName]=useState('')
    const [isLoading,setLoading]=useState(false)
    const [category,setCategory]=useState([])
    
    useEffect(() => {
        const getDetails = async () => {
            setLoading(true)
            if(localStorage.getItem('student')!==null){
                let emailAddress = localStorage.getItem('student');
                const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/
                    SELECT * FROM students WHERE EmailAddress='${emailAddress}'`);
                const data = await res.json();
                if (data.status) {
                    setCollege(data.message);
                    setCollegeName(data.message[0].CollegeName);
                    setBatchName(data.message[0].BatchName);
                }
            }
            setLoading(false)
        };
        const timer=setTimeout(() => {
            getDetails()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    }, []);

    useEffect(() => {
        if (collegeName && batchName && localStorage.getItem('student')!==null) {
            const fetchExamCategories = async () => {
                setLoading(true)
                const res1 = await fetch(`https://api.leadsoftbackend.com/api/student/getData/
                    SELECT * FROM exam_category WHERE CollegeName='${collegeName}' 
                    AND BatchName='${batchName}'`);                
                const data1 = await res1.json();
                if (data1.status) {  
                    let temp=[...data1.message]
                    temp = await Promise.all(temp.map(async (element) => {
                        const testCount = await fetchExamCount(element.CollegeName, element.BatchName, 
                            element.CategoryName);
                        return { ...element, testCount };
                    }));                                                     
                    setCategory(temp)    
                }
                setLoading(false)
            };            

            const timer=setTimeout(() => {
                fetchExamCategories()
            }, 500);
            return(()=>{
                clearTimeout(timer)
            })
            
        }
    }, [collegeName, batchName]);    
    const fetchExamCount = async (colgName,batchName,cat) => {
        setLoading(true)
        const res1 = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT COUNT(*) AS COUNT 
            FROM exam_assign WHERE CollegeName='${colgName}' AND BatchName='${batchName}' AND 
            isAccess=true AND CategoryName='${cat}'`);                
        const data1 = await res1.json();                
        if (data1.status) {
            return data1.message[0].COUNT
        }        
        setLoading(false)
    };
    const openCategory=(arg)=>{        
        const encodedParameter = btoa(arg.toString())
        navigate(`/test/exams/?param1=${encodedParameter}`);
    }
    return(
        <>
            <Header/>
            {isLoading && <WaitingPanel/>}
            {
                localStorage.getItem('student')===null ? (
                    <div className='login-information'>
                        <h2>Please Login first !!</h2>
                        <button onClick={login}>Click here for login </button>
                    </div>
                ) : (
                    <>
                    <div className='exam-category'>
                        <div className='title'>
                            <h2>Your Exam Categories</h2>
                        </div>
                        <div className='category'>
                            {
                                category.length > 0 ? (category.map((item,index)=>{
                                    return(
                                        <div className='card' key={index}>
                                            <div className='card-title1'>                                                
                                                <h2>{item.CategoryName}</h2>
                                            </div>
                                            <div className='card-footer'>
                                                <p>{item.testCount}/0</p>
                                                <p>Active</p>
                                                <button onClick={()=>openCategory(item.CategoryName)}>Open</button>
                                            </div>
                                        </div>
                                    )
                                })) :(<>
                                    <p>No Category Found !!</p>
                                </>)
                            }
                        </div>
                    </div>
                    </>
                )
            }
            
            <Footer/>
        </>
    )
}