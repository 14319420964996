import './WaitingPanel.css';

export default function WaitingPanel(){
    return(
        <>
            <div className='background1'>
                <div className="loading-circle-container">
                    <div className="loading-circle"></div>
                </div>
            </div>
        </>
    )
}