import React, { act, useState } from 'react';
import './FAQSection.css';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? 0 : index);
  };

  const faqs = [
    {
      question: "What is Placement Adhikar 2.0?",
      answer: "Placement Adhikar 2.0 is a comprehensive course designed for engineering graduates to prepare them for placements...",
    },
    {
      question: "What will I learn in this course?",
      answer: "The course includes placement-oriented content such as English and soft skills, quantitative reasoning...",
    },
    {
        question: "Who is the course designed for?",
        answer: "This course is designed for engineering passouts, BSc (Comp. Science), MCA, and BCA students looking for a job in the IT sector.",
    },
    {
      question: "What is the duration of the course?",
      answer: "The course duration will be for 2 months and placement support till you receive offer letter from us.",
    },
    {
      question: "Is there any support provided for off-campus hiring?",
      answer: "Yes, the course provides guidance for off-campus mega hiring...",
    },
    {
      question: "What is the fee structure for the course?",
      answer: "The fee structure can be viewed on the enrollment page...",
    },
    {
      question: "What resources will I have access to during the course?",
      answer: "You will have access to a practice exam platform with 30,000+ MCQs...",
    },
    {
      question: "Can I get a refund if I’m not satisfied with the course?",
      answer: "If you have attended any domain of the online session and taken a single support from us, then there is no refund. If you have attended 50% of the session and then you feel you want a refund, it is possible.",
    },
    {
      question: "Is it online or offline?",
      answer: "It is an online session with offline support.",
    }
  ];

  return (
    <div className="faq-section">
      <div className="banner">

      </div>
      <div className="info">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-container">
                {   
                    faqs.map((item,index)=>{
                        return(
                            <>
                            <div onClick={()=>setActiveIndex(index)} className={activeIndex===index ? 'faq-item-show':'faq-item'} key={index}>
                                <div className="title">
                                    <h1>{item.question}</h1>
                                    <i className="fa-solid fa-angle-down"></i>                        
                                </div>
                                <div className="answer">
                                    <p>{item.answer}</p>
                                </div>
                            </div>
                            </>
                        )
                    })
                }
                
                    
                
            </div>
      </div>
    </div>
  );
};

export default FAQSection;
