import { NavLink } from 'react-router-dom';
import './style.css';

export default function Footer(){
    return(
        <>
            <div className='footer'>
                <div className='image'>
                    <div className='logo'>

                    </div>
                    <div className='name'>
                        <p>LeadSoft Placement Academy</p>
                        <p>Grow with us...</p>
                    </div>
                </div>
                <div className='imp-links'>
                    <p>Important Links</p>
                    <ul>
                        <li><NavLink to='/courses'><i className="fa-solid fa-check"></i> Courses</NavLink></li>
                        <li><NavLink to='/'><i className="fa-solid fa-check"></i>  About</NavLink></li>
                        <li><NavLink to='/login'><i className="fa-solid fa-check"></i>  Login</NavLink></li>
                        <li><NavLink to='/profile'><i className="fa-solid fa-check"></i>  Profile</NavLink></li>
                        <li><NavLink to='/'><i className="fa-solid fa-check"></i>  Platform</NavLink></li>
                    </ul>
                </div>
                <div className='connect'>
                    <p>Connect us</p>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot"></i>
                        <p className='add'>Aai Building, Block No 4, Solapur - Aurangbabad highway, infront of Govt. Polytechnic College, Osmanabad, Maharashtra - 413501.</p>
                    </div>
                    <div className='item'>
                        <i className="fa-solid fa-phone-volume"></i>
                        <p className='add'>+91-7028816463 | +91-7387556463</p>
                    </div>
                    <div className='item'>
                        <i className="fa-solid fa-envelope"></i>
                        <p className='add'>suraj.pawar@leadsoft.academy | suraj.leadsoft@gmail.com</p>
                    </div>
                </div>
                <div className='form'>
                    <p>Get our updates</p>
                    <form>
                        <label>Your email address</label>
                        <input type='text'/>
                        <p className='desc'>Don't worry, we will not spam you !!</p>
                        <input type='submit' value='Get updates'/>
                    </form>
                </div>
            </div>
            <div className='copyright'>
                &copy; LeadSoft Placement Academy. All Rights Reserved.
            </div>
        </>
    )
}