import { useEffect, useRef, useState } from 'react';
import './Enroll.css';
import Footer from './Footer';
import Header from './Header';
import './style.css';
import './WaitingPanel.css';
import WaitingPanel from './WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FAQSection from './FAQSection';
import ThankYou from './ThankYou';

export default function Enroll(){
    const [isLoading,setLoading]=useState(false)
    const [isEnrolled,setEnrolled]=useState(false)
    const [enrollForm,setEnrollForm]=useState({
        firstName:'',middleName:'',lastName:'',emailAddress:'',
        mobile1:'',mobile2:'',highestQualification:'',
        dob:'',resume:null,promoCode:'',
        amount:'Rs. 2499.00'
    })
    const [regCount,setRegCount]=useState(0)
    const getRegisteredCount=async()=>{
        setLoading(true)
        const res = await fetch('https://api.leadsoftbackend.com/api/student/getData/SELECT COUNT(*) AS COUNT FROM course_registration')
        const data = await res.json()
        setLoading(false)
        if(data.status){
            setRegCount(data.message[0].COUNT)
        }
    }
    useEffect(()=>{
        getRegisteredCount()
    },[])
    const fileInputRef = useRef(null);
    const [errorText,setErrorText]=useState('')
    const hanleChange=(e)=>{
        setEnrollForm({
            ...enrollForm,
            [e.target.name]:e.target.value
        })
    }
    const handleResume=(e)=>{
        const file = e.target.files[0]
        if(file){
            setEnrollForm({
                ...enrollForm,
                resume:file
            })
        }
    }
    const promoClick=(e)=>{
        e.preventDefault()
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            let promoCode = enrollForm.promoCode
            if(promoCode==='PA2FREE100'){
                setEnrollForm({
                    ...enrollForm,
                    amount:'Rs. 0.00'
                })
                toast.success('You have saved Rs. 2499.00')
            } else if(promoCode==='SAVEWITHADHIKAR'){
                setEnrollForm({
                    ...enrollForm,
                    amount:'Rs. 1249.75'
                })
                toast.success('You have saved Rs. 1249.75')
            } else {
                setEnrollForm({
                    ...enrollForm,
                    amount:'Rs. 2449.00'
                })
                toast.error('Wrong promo code !!')
            }    
        }, 1500);        
    }
    const isValidate=()=>{
        
        if(enrollForm.firstName==='' || enrollForm.middleName===''||enrollForm.lastName===''){
            setErrorText('Enter your fullname !!')
            return false
        } else if(enrollForm.emailAddress===''){
            setErrorText('Enter your email address !!')
            return false
        } else if(enrollForm.mobile1===''){
            setErrorText('Enter your primary mobile number !!')
            return false
        } else if(enrollForm.mobile2===''){
            setErrorText('Enter your secondary mobile number !!')
            return false
        } else if(enrollForm.highestQualification===''){
            setErrorText('Select your highest qualification !!')
            return false
        } else if(enrollForm.dob===''){
            setErrorText('Select your date of birth !!')
            return false
        } else if(enrollForm.resume===null){
            setErrorText('Select your resume to upload !!')
            return false
        } else if(enrollForm.mobile1.length !=10 || enrollForm.mobile2.length!=10){
            setErrorText('Mobile number length must be 10.')
            return false
        } else{
            setErrorText('')
            return true;
        }
    }
    const courseRegistration=async(e)=>{
        e.preventDefault()
        
        if(!isValidate()){            
            return
        }
        setLoading(true)
        const formData = new FormData();
        formData.append('firstName', enrollForm.firstName);
        formData.append('middleName', enrollForm.middleName);
        formData.append('lastName', enrollForm.lastName);
        formData.append('emailAddress', enrollForm.emailAddress);
        formData.append('mobile1', enrollForm.mobile1);
        formData.append('mobile2', enrollForm.mobile2);
        formData.append('highestQualification', enrollForm.highestQualification);
        formData.append('dob', enrollForm.dob);
        formData.append('resume', enrollForm.resume);
        formData.append('promoCode', enrollForm.promoCode);
        formData.append('amount', enrollForm.amount);
        const res = await fetch('https://api.leadsoftbackend.com/api/student/api/courseReg',{
            method:'POST',
            body:formData
        })
        const data = await res.json()
        setLoading(false)
        if(data.status){
            setEnrolled(true)
            setEnrollForm({
                firstName:'',middleName:'',lastName:'',emailAddress:'',
                mobile1:'',mobile2:'',highestQualification:'',
                dob:'',resume:null,promoCode:'',
                amount:'Rs. 2499.00'
            })
        } else{
            toast.error(data.message)
        }
    }
    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            { isLoading && <WaitingPanel/>}
            <Header/>
            <div className='enroll-container'>
                <div className="info">
                    <h2>Join the Placement Adhikar 2.0 Course</h2>
                    <h3>Secure Your Future with Comprehensive Placement Preparation</h3>
                    <p>Are you an engineering graduate aiming for a successful placement? Look no further! The Placement Adhikar 2.0 course is meticulously designed to equip you with the skills and knowledge needed to excel in campus and off-campus placement drives.</p>
                </div>
                <div className="banner">

                </div>
            </div>
            <div className="enroll-info">
                <div className="card">
                    <div className="title">
                        <i className="fa-solid fa-user-tag"></i>
                    </div>
                    <h2>Profile Building</h2>
                    <ul>
                        <li>Resume Building</li>
                        <li>Web Portpolio</li>
                        <li>Cover Letter</li>                        
                    </ul>
                </div>
                <div className="card">
                    <div className="title">
                        <i className="fa-solid fa-yin-yang"></i>
                    </div>
                    <h2>Placement Preparation</h2>
                    <ul>
                        <li>Quants & Reasoning</li>
                        <li>Verbal Ability</li>
                        <li>Technical Ability</li>                        
                    </ul>
                </div>
                <div className="card">
                    <div className="title">
                        <i className="fa-solid fa-clipboard-list"></i>
                    </div>
                    <h2>Exam Platform</h2>
                    <ul>
                        <li>30,000+ Aptitude Q</li>
                        <li>5,000+ Tech MCQs</li>
                        <li>10,000+ Coding Q</li>                        
                    </ul>
                </div>
                <div className="card">
                    <div className="title">
                        <i className="fa-solid fa-headset"></i>
                    </div>
                    <h2>Campus Support</h2>
                    <ul>
                        <li>Exam Support</li>
                        <li>Drives Support</li>
                        <li>1 to 1 Mentorship</li>                        
                    </ul>
                </div>
                <div className="card">
                    <div className="title">
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                    </div>
                    <h2>Course Fees</h2>
                    <ul>
                        <li>Registration Amount</li>
                        <li>1 Month CTC After Placement</li>
                        <li className='check'>
                            <p>Rs. 4999.00</p>
                            <p>Rs. 2499.00</p>
                        </li>                        
                    </ul>
                </div>
            </div>

            <div className='enroll-form'>
                <div className="card1">
                    <div className="title">
                        <i className="fa-solid fa-graduation-cap"></i>
                    </div>
                    <div className="data">
                        <p>{65-regCount} of 65</p>
                        <p>Seat(s) Left</p>
                    </div>
                </div>
                <form onSubmit={courseRegistration}>  
                    <div className='title'>
                        <h2>Enroll Now in Placement Adhikar 2.0 and Secure Your Future!</h2>
                        <p>Join Placement Adhikar 2.0 by providing your details below. Unlock access to comprehensive placement preparation resources, expert guidance, and a step-by-step path to achieving your career goals. Don't miss out—start your journey to success today!</p>
                    </div>
                    <div className="form-container" >
                        <div className="input-field">
                            <label>First Name <span>*</span></label>
                            <input type='text' name='firstName' value={enrollForm.firstName} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Middle Name <span>*</span></label>
                            <input type='text' name='middleName' value={enrollForm.middleName} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Last Name <span>*</span></label>
                            <input type='text' name='lastName' value={enrollForm.lastName} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Email Address <span>*</span></label>
                            <input type='email' name='emailAddress' value={enrollForm.emailAddress} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Primary Mobile No <span>*</span></label>
                            <input type='number' name='mobile1' value={enrollForm.mobile1} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Secondary Mobile No <span>*</span></label>
                            <input type='number' name='mobile2' value={enrollForm.mobile2} onChange={hanleChange}/>
                        </div>
                        <div className="input-field">
                            <label>Highest Qualification <span>*</span></label>
                            <select name='highestQualification' value={enrollForm.highestQualification} onChange={hanleChange}>
                                <option value=''>SELECT</option>
                                <option value='BCA'>BCA</option>
                                <option value='BSc(Comp)'>BSc(Comp.)</option>
                                <option value='MCA'>MCA</option>
                                <option value='B.Tech'>B.Tech</option>
                                <option value='M.Tech'>M.Tech</option>
                            </select>
                        </div>
                        <div className="input-field">
                            <label>Date Of Birth <span>*</span></label>
                            <input type='date' name='dob' value={enrollForm.dob} onChange={hanleChange} />
                        </div>
                        <div className="input-field">
                            <label>Your Resume <span>*</span></label>
                            <input type='file' accept="application/pdf" name='resume' ref={fileInputRef} onChange={handleResume}/>
                        </div>
                        <div className="input-field">
                            <label>Promo Code</label>
                            <input type='text' name='promoCode' value={enrollForm.promoCode} onChange={hanleChange} style={{textTransform:'uppercase'}}/>
                        </div>
                        <div className="input-field">
                            <label>Your Registration Amount (Rs.) <span>*</span></label>
                            <input type='text' name='amount' value={enrollForm.amount} onChange={hanleChange} disabled/>
                        </div>
                        <div className="input-field">
                           <button onClick={promoClick}>Apply Promo Code</button>
                        </div>
                        <div className="error-field">
                            <p>{errorText}</p>
                        </div>
                        <input type='submit' value='Enroll Now !!'/>
                    </div>
                </form>
            </div>
            <FAQSection/>
            
            <Footer/>
            {isEnrolled && <ThankYou/>}
        </>
    )
}