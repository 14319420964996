
import './style.css';
import accenture from '../assets/accenture.png';
import atos from '../assets/atos.png';
import bajaj from '../assets/bajaj.webp';
import capgemini from '../assets/capgemini.png';
import cgi from '../assets/CGI.png';
import hcl from '../assets/HCL.png';
import hexaware from '../assets/hexaware.png';
import ibm from '../assets/IBM.png';
import infosys from '../assets/infosys.png';
import kpit from '../assets/KPIT.jpg';
import mindtree from '../assets/mindtree.png';
import mphasis from '../assets/mphasis.png';
import techm from '../assets/techm.png';
import tcs from '../assets/TCS.png';
import thinkitive from '../assets/thinkitive.png';
import wipro from '../assets/wipro.png';
import zensar from '../assets/zensar.png';
import jade from '../assets/jade.png';
import priti from '../assets/priti.jpeg';
import akansha from '../assets/akansha gaikwad.jpg';
import aishwarya from '../assets/aishwarya patil.jpg';
import samarth from '../assets/samarth.jpg';
import srushti from '../assets/srushti totad.jpg';
import shambhavi from '../assets/shambhavi.jpeg';
import ankita from '../assets/ankita.jpg';
import sushmita from '../assets/sushmita patil.jpg';
import renuka from '../assets/renuka1.jpg';
import muskan from '../assets/muskan.jpg';
import sanjana from '../assets/Sanjana Nakka.jpg';
import mamdyal from '../assets/mamdyal.jpeg';
import roshan from '../assets/roshan.jpg';
import women from '../assets/woman.webp';
import { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import abhishek from '../assets/abhishek (1).jpg';


export default function Homepage(){
    const aboutArr=[
        {attr:'Students Trained',value:'80000+'},{attr:'Courses Offered',value:'28+'},
        {attr:'College Tied-ups',value:'12+'},{attr:'Corporate Relations',value:'120+'},
        {attr:'Placed Students',value:'2800+'}
    ]
    const feedback=[        
        {
            name:'SHAMBHAVI SIDGIDDI',
            feedback:"I am shambhavi santosh Sidgiddi from shree siddheshwar women's college of engineering, solapur. Here i want to share my experience with LeadSoft IT solution company they have helped me through out the process of the selection . They plan and schedule for the preparation and help for the placement process. They are like backbone for placement. They are very supportive and very good kind of team they have . I Got the offer of CGI COMPANY WITH 3.91LPA . It happen only because of LeadSoft . Thank you so much for the help sir and a very special thank you Suraj sir.",
            image:shambhavi,
            keyword:['offer','suraj sir','magic','placement team']
        },
        {
            name:'Priti Sarkale',
            feedback:"It was a great experience in leadSoft I learned python very well that's why I got more interest in IT, Suraj Pawar Sir teaches us every concept in details. I am so glad that joined this classes and sir helped when we have some issues to understand tech concepts and now i got placed in Accenture, thank you so much sir for you guidance",
            image:priti,
            keyword:['great experience','dream job','suraj sir']
        },
        {
            name:'Samarth Rakhelkar',
            feedback:"If you want to fulfill your dreams & you want to get a job in IT company or you want to switch your career in IT... Then you can blindly trust on Leadsoft IT solutions. This is the best institute. Specifically Suraj sir , Suraj sir personally pay attention to every student and support them to improve their not only technical skills but also soft skills . I think Leadsoft IT solutions is the best platform to place in a reputed IT company.",
            image:samarth,
            keyword:['best training center','suraj sir','IT jobs']
        },
        {
            name:'Uma Gajul',
            feedback:"I'm Uma Chandrakant Gajul from Shree Siddheshwar Women's College Of Engineering, Solapur. Here I want to share my experience with LeadSoft IT Solutions Company. Finally I got offer letter from CGI with the package of 3.91 LPA.This is happened only because of LeadSoft IT Solutions Company. Because they have helped me a lot throughout the process like taking sessions , mock interviews and giving guidance.They are very supportive clears the each and every point. I'm very Thankful to their team and especially to Suraj sir Thanks a lot sir for the support and guidance.",
            image:women,
            keyword:['offer','suraj sir','supportive','sessions']
        },
        {
            name:'Akansha Gaikwad',
            feedback:"The planning for every campus drive is great like incredible. Many mock interviews are taken for the practice.Personally by my opinion Suraj sir take lots of efforts for the students placement.Thank you sir for your efforts...",
            image:akansha,
            keyword:['Hitachi','campus drive','suraj sir','incredible']
        },   
        {
            name:'Aishwarya Patil',
            feedback:"I  am Aishwarya Patil From Shree Siddheshwar Women's College of Engineering, Solapur. I Got placed in Capgemini with Package of 4.25LPA.Thanks to LeadSoft Team for helping me to improving my skills in Full stack Developement.Thanks To Suraj Pawar Sir and Vijay sir your guidance and support. Once again Thank's to leadsoft team for you support thought in this placement journey.Excellentttt",
            image:aishwarya,
            keyword:['Capgemini','teaching skills','4.25 LPA','Excellent Mocks']
        },
        {
            name:'Srushti Tothad',
            feedback:"Hello Everyone..!! I am Srushti Santosh Totad from Shri Siddheshwar Women's College of Engineering and I am from CSE department.I am placed in the multinational company named Capgemini with 4.25L package.I want to express my gratitude towards Leadsoft Placement academy for the support, training and guidance for the placement.In the training I got lot of knowledge of communication skills, technical skills and aptitude knowledge and also build our confidence by taking mock interviews that has helped me to crack the interview round of the Capgemini.Thank you so much Leadsoft Placement Academy.",
            image:srushti,
            keyword:['Capgemini','LeadSoft Placement Academy','Mindblowing Suraj Sir']
        },
        {
            name:'Ankita Bhogade',
            feedback:"I GOT PLACED IN IBM SYSTEM COMPANY WITH 4.25LPA.I'm really thankful to Suraj Pawar Sir & leadSoft solution for valuable help in securing this job opportunity.Your guidance , advice , and support were instrumental throughout the process . Your insights into the industry and your willingness to share your network truly made a difference. I am extremely grateful for your assistance .I'll be sure to keep you updated on my progress . Once again thank you for your tremendous support throughout this journey.",
            image:ankita,
            keyword:['IBM','Suraj Sir','Great Support & Guidance']
        },
        {
            name:'Sushmita Patil',
            feedback:"I got placed in Capgemini Company. I am really thankful towards our LeadSoft IT Solutions , for supporting and providing us opportunities to learn interview skills, communication skills and more guiding us in placements.as given quote of LeadSoft Solutions is grow with  us  it's really true .Ones again thank you so much sir.",
            image:sushmita,
            keyword:['Capgemini','really growing together','suraj sir']
        },
        {
            name:'Renuka Pogul',
            feedback:"I am Renuka pogul from Shree Siddeshwar Women's College of Engineering, Solapur. I got placed in Thinkitive Technologies,pune and the package is 4 LPA this is because of leadsoft IT solution company as they have guided me throughout the process also the training and the interview sessions has been held relevant to the placement. Specially thanks to Suraj pawar sir for your help to get placed. And your guidance and shared experience have been in valuable.I am grateful for the entire team of leadsoft IT solutions and Suraj sir. Thank you 🤗.",
            image:renuka,
            keyword:['Thinkitive','Fullstack Development']
        },
        {
            name:'Muskan Jamadar',
            feedback:"I am Muskan Mohammed Hanif Jamadar from Walchand Institute of Technology, Solapur. Here I want to share my experience with leadSoft IT Solutions. I got placed in IBM for the job role of Associate System Engineer with the package of 4.5 LPA. I very much appreciate the support and guidance I have received from the leadsoft. I specifically thanks Suraj Pawar Sir for your help in making my dream a reality. Thank you for your commitment to achieving my dream, your guidance and shared experiences have been invaluable.  I am grateful  for the entire team of leadSoft IT Solutions and Suraj sir. Thank you!!😊",
            image:muskan,
            keyword:['commitements','suraj sir','mocks','great guidance at each level']
        },
        {
            name:"Sanaja Nakka",
            feedback:"I am Sanjana Nakka from Shree Siddheshwar Women's College of Engineering, Solapur. I am thrilled to share that I got placed in Tech Mahindra as a web developer with a 6 LPA package. I’m incredibly grateful for all the support provided to me at every phase by offering well-organized training and mock sessions. The live sessions and real-time assignments were instrumental in enhancing my skills and preparing me for the role. Thank you, Prof. Suraj Pawar sir, and the entire team of Leadsoft IT Solutions, for your constant effort and support.",
            image:sanjana,
            keyword:['Web Development','real knowledge','great man','suraj sir']
        },
        {
            name:"Sanjana Mamdyal",
            feedback:"My name is Sanjana Mamdyal.I'm from Shree Siddheshwar Women's College of Engineering, Solapur. I got selected in CGI for 3.93 Package. I would like to say thanks to team for conducting training sessions and taking preparation from me. Special Thanks to Suraj Pawar Sir for their constant support.",
            image:mamdyal,
            keyword:['CGI','dream job','suraj sir','a man of commitement']
        },
        {
            name:"Roshan Kocheta",
            feedback:"Firstly thank you so much sir , Today I am selected In TCS  We are here today under your guidance. Even though this is our success, you have a big hand in this.I had a very great time learning from LeadSoft IT Solutions and being a part of it.with Suraj Pawar sir.He learn different skill.I'm very grateful to be a part of an internship!.Thank you Sir and Thank you LeadSoft for all the support, effort, guidance and values that you have given me.Once again thank you so much sir ...such a wonderful journey in LeadSoft familiar...Thank you very much sir",
            image:roshan,
            keyword:['TCS','handholding','guidance','wisdom man','suraj sir']
        },
        {
            name:"Abhishek Shah",
            feedback:"Suraj Sir - He always believed, Innovation, Goals, Teamwork, Commitment, Integrity and Responsibilities are the key values to have a successful career and Every opportunity Counts for it. And the belief of Sir stands true for me as it is his guidance and efforts that i got selected as a Software Engineer in Thinkitive Technologies Pvt. Ltd.Thank you Sir and Thank you LeadSoft for all the support, effort, guidance and values that you have given me.Lastly i will say that, We can Lead the world as we are from LeadSoft.",
            image:abhishek,
            keyword:['Thinkitive','Incredible experience','great mentor','suraj sir']
        }
    ]
    const [index,setIndex]=useState(0)
    const goNext=()=>{
        if(index===feedback.length-1){
            setIndex(0)
        } else {
            setIndex(index=>index+1)
        }
    }
    const goPrevious=()=>{
        if(index===0){
            setIndex(feedback.length-1)
        } else {
            setIndex(index=>index-1)
        }
    }
    const companies=[accenture,atos,bajaj,capgemini,cgi,hcl,hexaware,ibm,infosys,kpit,mindtree,mphasis,
        techm,tcs,thinkitive,wipro,zensar,jade
    ]
    return(
        <>
            <Header/>
            <div className='container'>
                <div className='content'>
                    <div className='data'>
                        <h1>Unlock Your Engineering Potential</h1>
                        <h2>Expert Placement Support & Preparation</h2> 
                        <p>At <strong>LeadSoft Placement Academy,</strong> we specialize in preparing engineering students for successful careers. With a focus on practical skills and real-world applications, our programs are designed to bridge the gap between academic knowledge and industry demands.</p>
                        <button>Get Started <i className="fa-solid fa-arrow-right"></i></button>
                        
                    </div>
                </div>
                <div className='bg'>

                </div>
            </div>
            <div className='container1'>
                {
                    aboutArr.map((item,index)=>{
                        return(
                            <div key={index} className='item'>
                                <p>{item.attr}</p>
                                <p>{item.value}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='container2'>
                <div className='title'>
                    <h2>About us</h2>
                </div>
                <div className='item'>
                    <div className='img'>

                    </div>
                    <div className='text'>
                        <p>LeadSoft Placement Academy, established in 2013, is a premier institute dedicated to transforming the career trajectories of engineering graduates. We specialize in providing comprehensive training and placement support, equipping students with the skills and confidence to secure their dream jobs in the competitive corporate world.</p>
                        <p>At LeadSoft, we bridge the gap between academic learning and industry demands through personalized guidance and cutting-edge training programs. Our curriculum is continuously updated to incorporate the latest industry trends and technologies, ensuring our students are well-prepared for the challenges ahead.</p>
                    </div>
                </div>
                <div className='item'>                    
                    <div className='text'>
                        <p>We take pride in our experienced faculty, who bring extensive industry knowledge and insights into the classroom. Our holistic approach includes not only technical training but also soft skills development, resume building, and mock interviews to enhance employability.</p>
                        <p>With a proven track record of successful placements in leading companies, we strive to nurture talent and empower our students to excel in their careers. Join LeadSoft Placement Academy and embark on a journey towards professional success with us.</p>
                    </div>
                    <div className='img'>

                    </div>
                </div>
            </div>
            <div className='container3'>
                <div className='title'>
                    <h2>Our Alumini Work at</h2>
                </div>
                <div className='companies'>
                    {
                        companies.map((item,index)=>{
                            return(
                                <div key={index} className='company'>
                                        <img src={item}/>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className='testimonials'>
                <div className='title'>
                    <h2>Candidate Testimonials</h2>
                </div>
                <div className='items'>
                    <div className='item'>
                        <img src={feedback[index].image}></img>
                        <div className='content'>
                            <h2>{feedback[index].name}</h2>
                            <p className='feedback'>{feedback[index].feedback}</p>
                            <div className='keywords'>
                                {
                                    feedback[index].keyword.map((keyw,idx)=>{
                                        return(
                                            <p key={idx} className='words'>{keyw}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>                  
                </div>
                <div className='actions'>
                        <button onClick={()=>goNext()}><i className="fa-solid fa-square-caret-left"></i></button>
                        <button onClick={()=>goPrevious()}><i className="fa-solid fa-square-caret-right"></i></button>
                   </div>
            </div>
            <Footer/>
        </>
    )
}