import './style.css';
import Header from './Header';
import Footer from './Footer';
import './Placement.css';
import accenture from '../assets/accenture.png';
import atos from '../assets/atos.png';
import bajaj from '../assets/bajaj.webp';
import capgemini from '../assets/capgemini.png';
import cgi from '../assets/CGI.png';
import hcl from '../assets/HCL.png';
import hexaware from '../assets/hexaware.png';
import ibm from '../assets/IBM.png';
import infosys from '../assets/infosys.png';
import kpit from '../assets/KPIT.jpg';
import mindtree from '../assets/mindtree.png';
import mphasis from '../assets/mphasis.png';
import techm from '../assets/techm.png';
import tcs from '../assets/TCS.png';
import thinkitive from '../assets/thinkitive.png';
import wipro from '../assets/wipro.png';
import zensar from '../assets/zensar.png';
import one from '../assets/1.jpg';
import two from '../assets/2.jpg';
import three from '../assets/3.jpg';
import four from '../assets/4.jpg';
import five from '../assets/5.jpg';
import six from '../assets/6.jpg';
import seven from '../assets/7.jpg';
import Marquee from "react-fast-marquee";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Placement(){
    const navigate=useNavigate()
    const test = useRef(null)
    const testImages=[one,two,three,four,five,six,seven]
   
    const onEnrollClick=()=>{
        navigate('/placement-adhikar-2.0/enroll')
    }

    const onPrevClick=()=>{
        if (test.current) {
            test.current.scrollBy({
                left: -700, 
                behavior: 'smooth'
            });
        }
    }
    const onNextClick=()=>{
        if (test.current) {
            test.current.scrollBy({
                left: 700,
                behavior: 'smooth'
            });
        }
    }

    return(
        <>
            <Header/>
            <div className="placement-container">
                <div className="info">                    
                    <h2>Secure Your Dream Job with Placement Adhikar 2.0 !!</h2>
                    <p>Master the skills that matter — English, soft skills, quants, reasoning, and technical expertise in coding and DSA. With over 30,000+ MCQs and 10,000+ coding questions from recent placement drives, plus expert faculty guidance, you’ll be fully prepared to ace any exam or interview. Build a standout resume, a winning web portfolio, and sharpen your interview skills. Don’t wait—enroll now and take the first step towards your successful career!</p>
                    <div className="action">
                        <div className="assistance">
                            
                        </div>
                        <button onClick={onEnrollClick}>Enroll Now !!</button>
                        <p className='offer'>Pay After Placement</p>
                    </div>
                </div>
                <div className="banner">
                
                </div>
            </div>            
            <div className="placement-services">
                <div className="title">
                    <h2>Placement Adhikar 2.0</h2>
                    <p>At Placement Adhikar 2.0, we offer a wide range of services designed to equip you with the skills, knowledge, and confidence needed to secure your dream job. <br></br>Explore our offerings below</p>
                </div>
                <div className="card-container">
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-wand-magic-sparkles"></i>
                            <h2>Comprehensive Skill Development</h2>
                        </div>
                        <p>Enhance your English, soft skills, quantitative reasoning, and technical abilities to excel in every aspect of the placement process.</p>
                    </div>
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-map"></i>
                            <h2>Extensive Exam Platform</h2>
                        </div>
                        <p>Access 30,000+ MCQs and 10,000+ coding questions from recent placement drives, preparing you for any challenge.</p>
                    </div>
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-shield-halved"></i>
                            <h2>Personalised Mentorship</h2>
                        </div>
                        <p>Learn from experienced faculty and participate in mock interviews to build confidence and refine your skills.</p>
                    </div>
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-building-columns"></i>
                            <h2>Career-Building Tools</h2>
                        </div>
                        <p>Craft a standout resume, build an impressive web portfolio, and write compelling cover letters with our expert guidance.</p>
                    </div>
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-handshake"></i>
                            <h2>Placement Support</h2>
                        </div>
                        <p>Receive guidance on off-campus hiring events and comprehensive exam support to crack any placement exam.</p>
                    </div>
                    <div className="card">
                        <div className="card-title">
                            <i className="fa-solid fa-rocket"></i>
                            <h2>Interview Preparation</h2>
                        </div>
                        <p>Develop interview skills and essential soft skills like teamwork, leadership, and problem-solving to thrive in any job.</p>
                    </div>
                </div>
            </div>
            <div className="m-container">
                <div className="title">
                    <h2>Companies Where Our Students Shine</h2>
                    <p>At Placement Adhikar 2.0, our commitment to your career success is reflected in the prestigious companies where our students have secured positions. Our comprehensive training equips you with the skills and confidence needed to thrive in today’s competitive job market. Here’s a glimpse of some of the top companies where our students have landed incredible opportunities.</p>
                </div>
                <Marquee>
                    <div className="i-container">
                        <img src={accenture} alt=''/>
                        <img src={atos} alt=''/>
                        <img src={bajaj} alt=''/>
                        <img src={capgemini} alt=''/>
                        <img src={cgi} alt=''/>
                        <img src={hcl} alt=''/>
                        <img src={hexaware} alt=''/>
                        <img src={ibm} alt=''/>
                        <img src={infosys} alt=''/>
                        <img src={kpit} alt=''/>
                        <img src={techm} alt=''/>
                        <img src={tcs} alt=''/>
                        <img src={mphasis} alt=''/>
                        <img src={mindtree} alt=''/>
                        <img src={thinkitive} alt=''/>
                        <img src={wipro} alt=''/>
                        <img src={zensar} alt=''/>
                    </div>
                </Marquee>
            </div>
            <div className="testomonial">
                <div className="info">
                    <h2>Hear From Our Successful Graduates</h2>
                    <p>At Placement Adhikar 2.0, we take pride in the achievements of our graduates. Our program is designed to transform potential into success, and the stories of our students are a testament to that. Below, you’ll find firsthand accounts from those who have benefitted from our comprehensive training and support. Discover how our tailored curriculum, expert guidance, and real-world practice have empowered these individuals to land their dream jobs and excel in their careers. Their success is our greatest reward, and their testimonials reflect the value and impact of our program.</p>
                </div>
                <div className="test">
                    <div className="test-container" ref={test}>
                        {
                            testImages.map((item,key)=>{
                                return(
                                    <img src={item} key={key}/>
                                )
                            })
                        }
                    </div>                                      
                    <button onClick={onPrevClick} className='btn-left'><i className="fa-solid fa-circle-left"></i></button>
                    <button onClick={onNextClick} className='btn-right'><i className="fa-solid fa-circle-right"></i></button>  
                </div>                
            </div>
            <div className="placement-action">
                <div className="element1">
                    <h2>Join Placement Adhikar 2.0 Today !!</h2>
                    <p>Hurry up!! Limited seats available.</p>
                    <p>Don’t leave your future to chance. Enroll in Placement Adhikar 2.0 and take the first step towards securing your dream job. With our comprehensive services, you’ll be fully equipped to navigate the placement process and achieve success.</p>
                    <div className="action">
                        <div className="assistance">
                            
                        </div>
                        <button onClick={onEnrollClick} className='btn-fill'>Enroll Now !!</button>
                        <button className='btn'>Download Broucher</button>
                    </div>
                </div>
                <div className="element2">

                </div>
            </div>
            <Footer/>
        </>
    )
}