import { useEffect, useRef, useState } from 'react';
import './Result.css';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Result() {
    const [isLoading,setLoading]=useState(false)
    const [examName,setExamName]=useState('')
    const [totalTimeTaken,setTotalTimeTaken]=useState('')
    const [examSections,setExamSections]=useState([])
    const [marksObtained,setMarksObtained]=useState(0)
    const [status,setStatus]=useState('')
    const hasRun=useRef(false)
    const navigate=useNavigate()
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const query=useQuery();
    
    const examId=atob(query.get('examId'))   
    const getExamName=async()=>{
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM exams WHERE SrNo=${examId}`)
        const data = await res.json()
        if(data.status){
            let updatedExamName = data.message[0].ExamName
            setExamName(updatedExamName)
        }
    }   
    const timeToSeconds = (time) => {
        const [minutes, seconds] = time.split(':').map(Number);
        return (minutes * 60) + seconds;
    };
    const secondsToTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const sec = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    };
    const submitExam=async(totalMarks,totalTime)=>{        
        let username=localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/submitExam`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                examId:examId,
                emailAddress:username,
                marks:totalMarks,
                timeTaken:totalTime
            })
        })
        const data = await res.json()   
        console.log(data)
        if(data.status){

        }
    }
    const getExamSections=async()=>{
        let username=localStorage.getItem('student')
        const getSectionData = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM test_section_result WHERE ExamId=${examId} AND EmailAddress='${username}'`)
        const attemptedSectionData = await getSectionData.json()        
        if(attemptedSectionData.status){
            let temp=0,temp1=0,temp2=0
            let updatedSections = attemptedSectionData.message
            for(let element of updatedSections){
                temp+=timeToSeconds(element.TimeTaken)
                temp1+=element.Positive
                temp2+=element.NoOfQuestions
            }
            setTotalTimeTaken(secondsToTime(temp))
            setMarksObtained(temp1)
            let percentage=((temp1/temp2)*100).toFixed(2)
            await submitExam(temp1,secondsToTime(temp))
            if(percentage>=60.0){
                setStatus('Qualified')
            } else {
                setStatus('Not Qualified')
            }
            setExamSections(attemptedSectionData.message)
        }
    }
    useEffect(()=>{
        if(hasRun.current){
            return
        }
        setLoading(true)
        getExamName()
        getExamSections()
        setTimeout(() => {
            setLoading(false)            
            hasRun.current=true
        }, 20000);
    },[])
    const viewYourAnswer=()=>{
        const encodedParameter = btoa(examId.toString())
        navigate(`/test/solution/?examId=${encodedParameter}`);
    }
    return (
        <>
            <div className="result-container">
                <div className="result-box">
                    {
                        isLoading &&
                        <div className="loader">
                            <div className="box">

                            </div>
                            <p>Submitting your answers...</p>
                        </div>
                    }      
                    {
                        !isLoading &&
                        <>
                            <div className='title'>
                                <h2>Your Test Result</h2>
                            </div>
                            <div className="info">
                                <div className="info-field">
                                    <label>Username</label>
                                    <p>{localStorage.getItem('student') || ''}</p>
                                </div>
                                <div className="info-field">
                                    <label>Exam Name</label>
                                    <p>{examName}</p>
                                </div>
                                <div className="info-field">
                                    <label>Time Taken</label>
                                    <p>{totalTimeTaken}</p>
                                </div>
                                <div className="info-field">
                                    <label>Marks Obtained</label>
                                    <p>{marksObtained} Marks</p>
                                </div>
                                <div className="info-field">
                                    <label>Status</label>
                                    <p>Completed</p>
                                </div>
                                <div className="info-field">
                                    <label>Exam Status</label>
                                    <p className={status==='Qualified' ? 'qualified':'not-qualified'}>{status}</p>
                                </div>
                            </div>
                            <div className="section-details">
                                <div className="title-section">
                                    <div className="title">
                                        <p>Section Wise</p>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Section Name</th>
                                                <th>Section Marks</th>
                                                <th>Question Type</th>
                                                <th>Total Q.</th>
                                                <th>Duration</th>
                                                <th>Time Taken</th>
                                                <th>Positive</th>
                                                <th>Negative</th>
                                                <th>Skipped Q.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            examSections.length > 0 ?(                                                
                                                examSections.map((item,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{item.SectionName}</td>
                                                            <td>{item.SectionMarks} Marks</td>
                                                            <td>{item.QuestionType}</td>
                                                            <td>{item.NoOfQuestions} Q</td>
                                                            <td>{item.Duration} mins</td>
                                                            <td>{item.TimeTaken}</td>
                                                            <td>{item.Positive}</td>
                                                            <td>{item.Negative}</td>
                                                            <td>{item.Skipped}</td>
                                                        </tr>
                                                    )
                                                })
                                            ):(
                                                <tr>
                                                    <td colSpan='9'>Loading section details...</td>
                                                </tr>
                                            )
                                        }                                            
                                        </tbody>
                                    </table>
                                </div>
                                <div className="action-footer">
                                    <h2>Thank you for attending the test.</h2>
                                    <button onClick={viewYourAnswer}>View your answers</button>
                                </div>
                            </div>
                        </>
                    }           
                </div>
            </div>

        </>
    )
}