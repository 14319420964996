import { useLocation } from 'react-router-dom';
import './ViewSolutions.css';
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';

function ViewSolutions(){
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const [examDetails,setExamDetails]=useState({})
    const [examSections,setExamSections]=useState([])
    const [examResult,setExamResult]=useState([])
    const [examQuestions,setExamQuestions]=useState([])
    const query=useQuery();    
    const examId=atob(query.get('examId')) 
    const emailAddress = localStorage.getItem('student')
    const [sectionQuestions,setSectionQuestions]=useState(0)
    const [selectedQuestion,setSelectedQuestion]=useState(1)
    const [selectedSection,setSelectedSection]=useState('')
    useEffect(()=>{
        const getExamDetails=async()=>{
            const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT ExamCategory,ExamName,TotalDuration,TotalMarks FROM exams WHERE SrNo=${examId}`)
            const data = await res.json()
            if(data.status){                
                setExamDetails(data.message[0])                
            }
        }
        const getExamResult=async()=>{
            let emailAddress = localStorage.getItem('student')
            const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT ObtainedMarks,TimeTaken,Status FROM test_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
            const data = await res.json()
            if(data.status){
                setExamResult(data.message[0])                
            }
        }
        const getExamSections=async()=>{
            let emailAddress = localStorage.getItem('student')
            const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM test_section_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
            const data = await res.json()            
            if(data.status){
                let temp=[...data.message]
                temp.forEach(element => {
                    element['active']=false
                });
                setExamSections(temp)
            }
        }
        const timer=setTimeout(() => {
            getExamDetails()
            getExamSections()
            getExamResult()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
        
    },[])
    const getExamQuestions=async(sectionName)=>{
        let emailAddress = localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com/api/student/getData/SELECT * FROM test_question_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}' AND SectionName='${sectionName}'`)
        const data = await res.json()      
        if(data.status){
            setExamQuestions(data.message)
        }
    }
    const onSectionClick=(item)=>{
        let temp=[...examSections]
        temp.forEach(element => {
            element['active']=false
        });
        setExamSections(temp)
        temp.forEach(element => {
            if(element.SectionName===item){

                element['active']=true
                setSelectedQuestion(1)
                getExamQuestions(element.SectionName)
                setSectionQuestions(element.NoOfQuestions)                
                setSelectedSection(element.SectionName)                
            } 
        });
        setExamSections(temp)
    }
    const goToPage=(pageNumber)=>{
        setSelectedQuestion(pageNumber)
        console.log(examQuestions[pageNumber-1])
    }
    return(
        <>
            <Header/>
            <div className="view-container">
                <div className="box">
                    <label>Select Section</label>
                    <select onChange={(e)=>onSectionClick(e.target.value)}>
                        <option value=''>SELECT SECTION</option>
                        {
                            examSections.map((item,index)=>{
                                return(
                                    <option value={item.SectionName} key={index}>{item.SectionName}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="box">
                    <label>Username</label>
                    <p>{emailAddress}</p>
                </div>
                <div className="box">
                    <label>Exam Details</label>
                    <p>{examDetails.ExamCategory} | {examDetails.ExamName}</p>
                </div>
            </div>
            {
                selectedSection!=='' &&
            <div className="solution-box">
                <div className="question-box">
                
                <div className="view-solution">
                    <div className="question-no">
                        <p>Question : {selectedQuestion}/{sectionQuestions}</p>
                    </div>
                    <div className='question'>
                        <p dangerouslySetInnerHTML={{__html:examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].QuestionContent:''}} />
                    </div>
                    <div className="options">
                        <div className="option">
                            <div className={ examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 1'? examQuestions[selectedQuestion-1].Answer==='Option 1' ? 'selected':'wrong':'box'}>
                                {
                                    (examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 1') ?
                                    (examQuestions[selectedQuestion-1].Answer==='Option 1') ? <i class="fa-solid fa-circle-check"></i> :
                                    <i class="fa-solid fa-circle-xmark"></i>:'' 
                                }
                              
                            </div>    
                            <p dangerouslySetInnerHTML={{__html:examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].Option1 :''}}/>
                        </div> 
                        <div className="option">
                            <div className={ examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 2'? examQuestions[selectedQuestion-1].Answer==='Option 2' ? 'selected':'wrong':'box'}>
                                {
                                    (examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 2') ?
                                    (examQuestions[selectedQuestion-1].Answer==='Option 2') ? <i class="fa-solid fa-circle-check"></i> :
                                    <i class="fa-solid fa-circle-xmark"></i>:'' 
                                }
                            </div>    
                            <p dangerouslySetInnerHTML={{__html:examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].Option2:''}}/>
                        </div> 
                        <div className="option">
                            <div className={ examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 3'? (examQuestions[selectedQuestion-1].Answer==='Option 3' ? 'selected':'wrong'):'box'}>
                                {
                                    (examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 3') ?
                                    (examQuestions[selectedQuestion-1].Answer==='Option 3') ? <i class="fa-solid fa-circle-check"></i> :
                                    <i class="fa-solid fa-circle-xmark"></i>:'' 
                                }
                            </div>    
                            <p  dangerouslySetInnerHTML={{__html:examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].Option3:''}}/>
                        </div> 
                        <div className="option">
                            <div className={ examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 4'? examQuestions[selectedQuestion-1].Answer==='Option 4' ? 'selected':'wrong':'box'}>
                                {
                                    (examQuestions[selectedQuestion-1] && examQuestions[selectedQuestion-1].CandidateAnswer==='Option 4') ?
                                    (examQuestions[selectedQuestion-1].Answer==='Option 4') ? <i class="fa-solid fa-circle-check"></i> :
                                    <i class="fa-solid fa-circle-xmark"></i>:'' 
                                }
                            </div>    
                            <p dangerouslySetInnerHTML={{__html:examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].Option4:''}}/>
                        </div>    
                    </div>
                   
                    <div className="status">
                        <p className={examQuestions[selectedQuestion-1] ? (examQuestions[selectedQuestion-1].Status==='Correct' ? 'correct':'st'):'' }>{examQuestions[selectedQuestion-1] ? examQuestions[selectedQuestion-1].Status:''}</p>
                        <div className="box">
                            <label>Correct Answer :</label> 
                            <p>{examQuestions[selectedQuestion-1]?examQuestions[selectedQuestion-1].Answer:''}</p>
                        </div>
                    </div>
                </div>
            
                </div>
                <div className="question-sequence">
                    {
                         Array.from({length:sectionQuestions},(_,i)=>i+1).map((page)=>{
                            return(
                                <button onClick={()=>goToPage(page)} className={page===selectedQuestion ? 'active':''} key={page}>{page}</button>
                            )
                        })
                    }
                </div>
            </div>
            }
            <Footer/>
        </>
    );
}

export default ViewSolutions;