import { useNavigate } from 'react-router-dom';
import './ThankYou.css';

export default function ThankYou(){
    const navigate=useNavigate()
    const goToDashboard=()=>{
        navigate('/')
    }
    return(
        <>
            <div className='thank-you-message-container'>
                    <div className='thank-you-message'>
                        <h2>Thank you for enrolling !!</h2>
                        <p>Congratulations on taking the first step towards your future! You are now enrolled in the Placement Adhikar 2.0 course.<br></br> We are excited to have you on board and look forward to helping you achieve your career goals.</p>
                        <p>Our team will contact you soon with further details related to your course admission. Please keep an eye on your email and phone for updates.</p>
                        <button onClick={goToDashboard}>Go to homepage</button>
                    </div>
            </div>
        </>
    )
}